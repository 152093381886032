import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import Axios from "axios";
import store from "./store/index.js";

require("./assets/styles/colors.css");
require("./assets/styles/containers.css");
require("./assets/styles/dialogs.css");
require("./assets/styles/buttons.css");
require("./assets/styles/inputs.css");
require("./assets/styles/widgets.css");
require("./assets/styles/table.css");

const app = createApp(App).use(router, Axios, store);

app.config.globalProperties.$store = store;

import VueApexCharts from "vue3-apexcharts";
app.use(VueApexCharts);

import PrimeVue from "primevue/config";
import "primevue/resources/themes/bootstrap4-light-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import ToastService from "primevue/toastservice";
app.use(PrimeVue);
app.use(ToastService);

import Menubar from "primevue/menubar";
app.component("Menubar", Menubar);
import InputText from "primevue/inputtext";
app.component("InputText", InputText);
import Textarea from "primevue/textarea";
app.component("Textarea", Textarea);
import Button from "primevue/button";
app.component("Button", Button);
import Toast from "primevue/toast";
app.component("Toast", Toast);
import DataTable from "primevue/datatable";
app.component("DataTable", DataTable);
import Paginator from "primevue/paginator";
app.component("Paginator", Paginator);
import Column from "primevue/column";
app.component("Column", Column);
import Dialog from "primevue/dialog";
app.component("Dialog", Dialog);
import ColorPicker from "primevue/colorpicker";
app.component("ColorPicker", ColorPicker);
import InputSwitch from "primevue/inputswitch";
app.component("InputSwitch", InputSwitch);
import Dropdown from "primevue/dropdown";
app.component("Dropdown", Dropdown);
import TabView from "primevue/tabview";
app.component("TabView", TabView);
import TabPanel from "primevue/tabpanel";
app.component("TabPanel", TabPanel);
import Fieldset from "primevue/fieldset";
app.component("Fieldset", Fieldset);
import Timeline from "primevue/timeline";
app.component("Timeline", Timeline);

app.mount("#app");
