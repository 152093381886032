import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/iniciar-sesion",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
    meta: {
      display: "Iniciar sesión",
      auth: false,
      title: "Iniciar sesión",
    },
  },
  {
    path: "/",
    name: "Dashboard",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/Dashboard.vue"),
    meta: {
      display: "Inicio / Dashboard",
      auth: true,
      title: "Dashboard",
    },
  },
  {
    path: "/reportes",
    name: "Reports",
    component: () =>
      import(/* webpackChunkName: "reports" */ "../views/Reports.vue"),
    meta: {
      display: "Inicio / Reportes",
      auth: true,
      title: "Reportes",
    },
  },
  {
    path: "/usuarios",
    name: "Users",
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/Users.vue"),
    meta: {
      display: "Inicio / Usuarios",
      auth: true,
      title: "Usuarios",
    },
  },
  {
    path: "/secretarias",
    name: "Secretaries",
    component: () =>
      import(/* webpackChunkName: "secretaries" */ "../views/Secretaries.vue"),
    meta: {
      display: "Inicio / Secretarías",
      auth: true,
      title: "Secretarías",
    },
  },
  {
    path: "/tipos/incidente",
    name: "IncidentTypes",
    component: () =>
      import(
        /* webpackChunkName: "incidentTypes" */ "../views/IncidentTypes.vue"
      ),
    meta: {
      display: "Inicio / Tipos de incidente",
      auth: true,
      title: "Tipos de incidente",
    },
  },
  {
    path: "/tipos/usuario",
    name: "UserTypes",
    component: () =>
      import(/* webpackChunkName: "userTypes" */ "../views/UserTypes.vue"),
    meta: {
      display: "Inicio / Tipos de usuario",
      auth: true,
      title: "Tipos de usuario",
    },
  },
  {
    path: "/historial",
    name: "History",
    component: () =>
      import(/* webpackChunkName: "history" */ "../views/History.vue"),
    meta: {
      display: "Configuración / Historial",
      auth: true,
      title: "Historial",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.auth);
  const isAuthenticated = sessionStorage.getItem("cfta");

  if (requiresAuth && isAuthenticated == null) {
    next("/iniciar-sesion");
  } else if (isAuthenticated != null && to.name == "Login") {
    next("/");
  } else {
    next();
  }
});

export default router;
