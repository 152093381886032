import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

export default createStore({
  plugins: [createPersistedState()],
  state() {
    return {
      user: null,
    };
  },
  mutations: {
    setUser(state, data) {
      state.user = data;
    },
  },
  getters: {
    getUser(state) {
      return state.user;
    },
  },
});
